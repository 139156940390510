const plugin = require('tailwindcss/plugin')
const colors = require('tailwindcss/colors')
const defaultTheme = require('tailwindcss/defaultTheme')

module.exports = {
  purge: ['./pages/**/*.tsx', './components/**/*.tsx'],
  darkMode: false,
  theme: {
    extend: {
      backgroundImage: theme => ({
        'on-your-team': "url('/images/product-pages/Why-XL-2.png')"
      }),
      colors: {
        brand: '#0a3782',
        accentBlue: '#2174a5',
        gold: {
          50: '#f9f5e5',
          100: '#faf0bd',
          200: '#f6e67c',
          300: '#f1d439',
          400: '#ffbf00',
          500: '#e19706',
          600: '#cf7404',
          700: '#ae5708',
          800: '#8e440e',
          900: '#743710'
        },
        orange: colors.orange,
        gray: colors.trueGray,
        red: colors.red
      },
      fontFamily: {
        ...defaultTheme.fontFamily,
        heading: ['Lato', ...defaultTheme.fontFamily.sans],
        sans: ['Roboto', ...defaultTheme.fontFamily.sans],
        mono: ['Inconsolata', ...defaultTheme.fontFamily.mono],
        checkd: ['Averta', ...defaultTheme.fontFamily.sans]
      },
      zIndex: {
        '-10': '-10'
      }
    }
  },
  variants: {
    extend: {
      borderRadius: ['first', 'last'],
      backgroundColor: ['even', 'disabled'],
      textColor: ['disabled', 'active'],
      opacity: ['disabled'],
      cursor: ['disabled'],
      transform: ['hover']
    }
  },
  plugins: [
    plugin(({ addBase, config }) => {
      addBase({
        h1: { fontFamily: config('theme.fontFamily.heading').join(', ') },
        h2: { fontFamily: config('theme.fontFamily.heading').join(', ') },
        h3: { fontFamily: config('theme.fontFamily.heading').join(', ') },
        h4: { fontFamily: config('theme.fontFamily.heading').join(', ') },
        h5: { fontFamily: config('theme.fontFamily.heading').join(', ') },
        h6: { fontFamily: config('theme.fontFamily.heading').join(', ') }
      })
    }),
    plugin(({ addUtilities }) => {
      addUtilities({
        '.folded-l': {
          'clip-path': 'polygon(10% 0,100% 0,100% 100%,0 100%,0 30%)'
        },
        '.folded-l-15': {
          'clip-path': 'polygon(10% 0,100% 0,100% 100%,0 100%,0 15%)'
        },

        '.folded-r': {
          'clip-path': 'polygon(77% 0, 100% 30%, 100% 100%, 0 100%, 0 0)'
        }
      })
    })
  ]
}
